.modal {
    height: max-content;
}
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){
    
}
@media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
        .modal {
            height: auto;
        }
        .modal-content {
            border-radius: 15px;
            border: none;
            padding: 0px 20px 0px 18px !important;
        }
    }
}
.modal-content {
    border-radius: 15px;
    border: none;
    padding: 0px 22px 0px 18px;
}
.modal-body {
    padding: 0;
}
.modal-header {
    border: none;
}
.close {
    padding-bottom: 0px !important;
}
