.layout-one {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    &-col {
        & :last-child {
            border-bottom: 0;
        }
        text-align: center;
        &-border {
            border-bottom: 1px solid #e9e9e9;
        }
        &-logo img {
            margin-top: 1em;
            width: 14em;
        }
        &-title {
            margin-top: 1em;
            color: white;
            font-weight: bold;
            font-size: 1.7em;
        }
        &-subtitle {
            color: white;
            font-size: 1.05em;
        }
        &-html-top {
            color: white;
            margin-top: 1em;
        }
        &-html-bottom {
            color: white;
            margin-top: 0em;
            font-size: 0.9em;
        }
        &-inputs {
            color: white;
            border-radius: 10px;
            margin-top: 2em; 
        }
        &-inputs input {
            width: 100%;
            height: 3.2em;
            border: none;
        }
        &-inputs input:focus {
            outline: none;
        }
        &-select {
            color: #757575;
            border: 0;
            width: 100%;
            height: 3.2em;
        }
        &-checkbox {
            color: white;
            margin-top: 1.5em;
            font-size: 0.8em;
        }
        &-checkbox input {
            margin-right: 0.4em;
        }
        &-checkbox span {
            margin-left: 0.4em;
            cursor: pointer;
        }
        &-checkbox span:hover {
            text-decoration: underline;
        }
        &-button input {
            width: 100%;
            padding: 0.9em;
            font-weight: bold;
            cursor: pointer;
            margin-top: 2em;
            border: 0;
            border-radius: 5px;
            color: white;
        }
    }
    &-col-wide {
        & :last-child {
            border-bottom: 0;
        }
        text-align: center;
        &-border {
            display: flex;
            border-bottom: 1px solid #e9e9e9;
            &-left {
                padding-left: 0.4em;
            }
        }
        &-logo img {
            width: 14em;
        }
        &-title {
            margin-top: 2.5em;
            color: white;
            font-weight: bold;
            font-size: 1.7em;
        }
        &-subtitle {
            color: white;
            font-size: 1.05em;
        }
        &-inputs {
            color: white;
            border-radius: 10px;
            background-color: white;
            margin-top: 2em
        }
        &-select {
            color: #757575;
            border: 0;
            width: 100%;
            height: 3.2em;
            white-space: nowrap;
            background-color: white;
            &-border-left {
                padding-left: 0.4em;
            }
        }
        &-select:focus {
            outline: none;
        }
        &-inputs input {
            width: 100%;
            height: 3.2em;
            border: none;
            color: red;
        }
        &-inputs input:focus {
            outline: none;
        }
        &-checkbox {
            color: white;
            font-size: 0.8em;
            margin-bottom: 0.5em;
        }
        &-checkbox input {
            width: 1em;
            height: 1em;
            margin-right: 0.2em;
        }
        &-checkbox span {
            color: white !important;
            margin-left: 0.2em;
            cursor: pointer;
        }
        // &-checkbox span:hover {
        //     text-decoration: underline;
        // }
        &-checkbox-underline {
            text-decoration: underline;
        }

        &-checkbox label {
            margin-bottom: 0;
            text-align: left;
        }
        &-button input {
            width: 100%;
            padding: 0.9em;
            font-weight: bold;
            cursor: pointer;
            margin-top: 2em;
            border: 0;
            border-radius: 5px;
            color: white;
        }
    }
}
