.layout-two {
    width: 99.2vw;
    &-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        & img {
            width: 24vw;
            margin-top: 2.5em;
        }
        &-row {
            display: flex;
            justify-content: center;
        }
        &-html {
            width: 80%;
            margin-top: 26%;
        }
    }
    &-right {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 2.5em;
        & label {
            color: #535d66;
            font-size: 0.9em;
        }
        & h1 {
            color: #555555;
            font-weight: bold;
            margin-bottom: 0.8em;
            margin-left: 12px;
        }
        & p {
            color: #747d85;
            font-size: 0.6em;
            width: 95%;
            margin: 0;
        }
        &-input {
            width: 100%;
            height: 2.8em;
            background-color: #f5f7f8;
            border: 0;
            border-radius: 5px;
            padding-left: 10px;
            padding-right: 10px;
            &-small {
                display: flex;
                flex-direction: column;
                width: 48%;
                & span {
                    color: #ff1c0f;
                    font-size: 1.1em;
                }
            }
            &-large {
                display: flex;
                flex-direction: column;
                width: 100%;
                & span {
                    color: #ff1c0f;
                    font-size: 1.1em;
                }
            }
            &-checkbox {
                height: auto;
                font-size: 0.8em;
                margin-bottom: 0em;
                & span {
                    font-size: 0.9em;
                }
                & input {
                    position: relative;
                    margin-right: 10px;
                    top: 0.2em;
                }
                &-required {
                    color: #ff1c0f;
                    font-size: 1.1em;
                }
                &-modallink {
                    margin-left: 5px;
                    cursor: pointer;
                    color: #555555;
                    text-decoration: underline;
                    margin-bottom: 0.2;
                }


                // &-modallink:hover {
                //     text-decoration: underline;
                // }
                & label {
                    margin: 0;
                }
            }
        }
        &-select {
            width: 100%;
            height: 3.6em;
            background-color: #f5f7f8;
            border: 0;
            border-radius: 5px;
            color: #798087;
            font-size: 0.8em;
            padding-left: 0.8em;
            &-small {
                display: flex;
                flex-direction: column;
                width: 48%;
                & span {
                    color: #ff1c0f;
                    font-size: 1.1em;
                }
            }
            &-large {
                display: flex;
                flex-direction: column;
                width: 100%;
                & span {
                    color: #ff1c0f;
                    font-size: 1.1em;
                }
            }
        }
        &-select:focus {
            outline: none;
        }
        &-form {
            width: 90%;
            margin-left: 8vw;
        }
        &-row {
            width: 90%;
            margin-top: 1em;
            &-help {
                margin-top: 0.5em;
            }
        }
        &-button {
            bottom: 0px;
            margin-bottom: 1.5em;
            transition: all 0.4s ease;
            & input {
                width: 100%;
                height: 3.4em;
                margin-top: 1.5em;
                border: 0;
                border-radius: 18px;
                color: white;
                font-size: 1.2em;
                cursor: pointer;
            }
            & input:hover {
                background-color: var(--item-bg-color);
                box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
            }
        }
        &-button:hover {
            // bottom: 3px;
        }
    }
}

@media (max-width: 767px) {
    .layout-two {
        &-left {
            & img {
                width: 22em;
                margin-top: 3em;
            }
        }
    }
}
