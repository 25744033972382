.select-multi {
    width: 50%; 
    margin: auto;  
    & input {
        height: 1em !important;
    }
}
.select-multi-wide {
    width: 100%; 
    margin: auto;  
    & input {
        height: 1em !important;
    }
}
.css-g1d714-ValueContainer {
    padding: 0;
    height: fit-content;
    // max-height: 50px;
    height: 50px;
}
